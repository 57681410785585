import React from "react"
//import FadeInCharsEffect from "../effects/FadeInCharsEffect"

const HeadlineReveal = ({ children, tagName = "div", className, ...props }) => {
  const TagName = tagName

  // return (
  //   <FadeInCharsEffect
  //     tagName={tagName}
  //     className={`headline-reveal ${className}`}
  //     {...props}
  //   >
  //     {children}
  //   </FadeInCharsEffect>
  // )

  return (
    <TagName className={className} {...props}>
      {children}
    </TagName>
  )
}

export default HeadlineReveal
