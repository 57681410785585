import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const SubNavBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="screen"
      blockPadding="pb-block -mt-16"
    >
      <div className="max-w-screen-xl mx-auto lg:px-4">
        <nav className="bg-gray-100 overflow-y-scroll-indicator">
          <ul>
            {block.subNavItems?.map((item, index) => (
              <li key={index}>
                <Link
                  className="inline-block p-4 font-semibold bg-gray-100 whitespace-nowrap hover:bg-primary hover:text-white active:bg-primary active:text-white "
                  link={item.link}
                  activeClass="bg-primary text-white"
                >
                  {item.link_text}
                </Link>
              </li>
            ))}
            <li className="w-full">
              <div
                className="flex w-full h-full bg-gray-100"
                style={{ minWidth: "1rem" }}
              ></div>
            </li>
          </ul>
        </nav>
      </div>
    </BlockWrapper>
  )
}

export default SubNavBlock
