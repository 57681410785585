import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import Icon from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Icon"

const Button = ({
  children,
  link,
  externalIcon,
  icon,
  className,
  target,
  onClick,
  isDark = false,
  btnType = "primary",
}) => {
  const baseClasses = "btn group"
  const buttonType = isDark ? `btn-dark-${btnType}` : `btn-${btnType}`

  if (link) {
    return (
      <Link
        className={`${baseClasses} ${buttonType} ${className ? className : ""}`}
        link={link}
        externalIcon={externalIcon}
        target={target}
        onClick={onClick}
      >
        {icon !== undefined && icon !== "" && (
          <Icon icon={icon} size="1x" className="mr-2" />
        )}
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={`${baseClasses} ${buttonType} ${className ? className : ""}`}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {icon !== undefined && icon !== "" && (
          <Icon icon={icon} size="1x" className="mr-2" />
        )}
        {children}
      </button>
    )
  }
}

export default Button
