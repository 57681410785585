import React from "react"
import HeadlineReveal from "../reveals/HeadlineReveal"
import TextReveal from "../reveals/TextReveal"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

const HeadlineEnhanced = ({
  kicker,
  headline,
  headlineLevel = "h2",
  subheadline,
  className,
  kickerClass = "",
  headlineClass = "",
  subheadlineClass = "",
  tagName,
  ...props
}) => {
  let hasKicker = kicker && kicker.length > 0 ? true : false
  let hasHeadline = headline && headline.length > 0 ? true : false
  let hasSubheadline = subheadline && subheadline.length > 0 ? true : false

  return (
    <>
      {(hasKicker || hasHeadline || hasSubheadline) && (
        <div className={className}>
          {hasKicker && (
            <TextReveal
              className={`kicker kicker-${headlineLevel} ${
                hasHeadline || hasSubheadline ? "mb-2" : ""
              } ${kickerClass}`}
              tagName="span"
            >
              {kicker}
            </TextReveal>
          )}

          {hasHeadline && (
            <HeadlineReveal className={`${hasSubheadline ? "mb-2" : ""}`}>
              <Headline
                headline={headline}
                headlineLevel={headlineLevel}
                tagName={tagName}
                className={"relative inline-block " + headlineClass}
              />
            </HeadlineReveal>
          )}
          {hasSubheadline && (
            <TextReveal
              className={`subheadline subheadline-${headlineLevel}  ${subheadlineClass}`}
              tagName="span"
            >
              {subheadline}
            </TextReveal>
          )}
        </div>
      )}
    </>
  )
}

export default HeadlineEnhanced
