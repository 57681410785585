import React from "react"
//import FadeInBottomEffect from "../effects/FadeInBottomEffect"

const ImageReveal = ({ children, tagName = "div", className, ...props }) => {
  const TagName = tagName

  // return (
  //   <FadeInBottomEffect
  //     tagName={tagName}
  //     className={`image-reveal ${className}`}
  //     {...props}
  //   >
  //     {children}
  //   </FadeInBottomEffect>
  // )

  return (
    <TagName className={className} {...props}>
      {children}
    </TagName>
  )
}

export default ImageReveal
